"use strict";
/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import API_CONFIG from "./api.config";
// import { logout, getToken, relogin } from "@/utils/auth";
import { commonError } from "./error";

// Full config:  https://github.com/axios/axios#request-config
// VGC_BASE_V4_API
// VGC_BASE_TEST_V4_API
export let baseURL = process.env.NODE_ENV === "production" ? API_CONFIG.VGC_BASE_V4_API : API_CONFIG.VGC_BASE_V4_API;

window.controller = new AbortController();
// controller.abort();

// const CancelToken = axios.CancelToken;
// window.source = CancelToken.source();
// source.cancel('Operation canceled by the user.'); // 取消请求的方法

let config = {
    baseURL: baseURL,
    timeout: 60 * 100000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
    // cancelToken: source.token,
    // signal: window.controller.signal
};

let api = axios.create(config);








api.interceptors.request.use(
    config => {
        // if (getToken()) {
        //     config.headers.Authorization = getToken();
        //     config.headers["Accept-Language"] = "zh-CN";
        // }
        return config;
    },
    error => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    response => {
        // Do something with response data
        return response;
    },
    error => {
        // if (error.response.status === 401) {
        //     let whiteLists = ['/api/v4/auth/login']
        //     if (!whiteLists.includes(error.response.data.path)) {
        //         logout()
        //         relogin()
        //     }
        //     // else {relogin(error.response.data.message)}
        // }
        // if (error.response.status === 404) {
        //     commonError("404错误", error.response.data);
        //     return Promise.reject()
        // }
        if (error.response.status >= 500) {
            commonError("服务器错误", error.response.data);
            return Promise.reject()
        }
        return Promise.reject(error.response.data);
    }
);
export {
    api
}

Plugin.install = function (Vue, options) {
    Vue.api = api;
    window.api = api;
    Object.defineProperties(Vue.prototype, {
        api: {
            get() {
                return api;
            }
        },
        $api: {
            get() {
                return api;
            }
        }
    });
};

Vue.use(Plugin);

export default Plugin;
