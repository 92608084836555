import { api } from "@/request/api";
import qs from "qs";

/**
 * @param {Object} params 获取SAP公开定单产品
 */
export function GetLogisticsList(params = {}) {
    return new Promise((resolve, reject) => {
        api.get(`/sales/orders/logistics/pub?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`).then(res => {
            if (res.status === 200 || res.status === 206) {
                resolve(res)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 获取公开物流详情
 * @param {Number} id 
 */
export function GetLogisticsDetail(id) {
    return new Promise((resolve, reject) => {
        api.get(`/sales/orders/logistics/${id}/pub`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取公开物流产品汇总数
 * @param {Number} id 
 */
export function GetLogisticsProductPubSummary(id) {
    return new Promise((resolve, reject) => {
        api.get(`/sales/orders/logistics/${id}/products/summary/pub`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * @param {Object} params 获取SAP公开产品
 */
export function GetLogisticsProductList(id = null, params = {}) {
    return new Promise((resolve, reject) => {
        api.get(`/sales/orders/logistics/${id}/products/pub?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`).then(res => {
            if (res.status === 200 || res.status === 206) {
                resolve(res)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * @param {Object} params 获取物流公开记录
 */
export function GetLogisticsLogsList(id = null, params = {}) {
    return new Promise((resolve, reject) => {
        api.get(`/sales/orders/logistics/${id}/logs/pub?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`).then(res => {
            if (res.status === 200 || res.status === 206) {
                resolve(res)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * @param {Object} params 签收
 */
export function PutLogisticsSign(id = null, params = {}) {
    return new Promise((resolve, reject) => {
        api.put(`/sales/orders/logistics/${id}//sign/pub`, params).then(res => {
            if (res.status === 200 || res.status === 201) {
                resolve(res)
            }
        }).catch(err => {
            reject(err)
        })
    })
}