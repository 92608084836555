// import { Notification } from "element-ui";
let errorCodes = [401, 500]
export const ShowApiError = (err, title = "请求错误") => {
  if (!err) {
    return false
  }
  if (errorCodes.includes(err.status)) {
    return err
  }
  // let errorList = ''
  // if (err.errors && err.errors.length > 0) {
  //   err.errors.forEach((itemErr,index) => {
  //     errorList += `<p class='text-danger'>${index+1}、${itemErr.defaultMessage} </p>`
  //   })
  // }
  alert(title);
  // Notification.error({
  //   title: title,
  //   position: "bottom-right",
  //   duration: 1500,
  //   dangerouslyUseHTMLString: true,
  //   message: `<p>状态码：${err.status} </p>
  //       <p>错误类型：${err.error} </p>
  //       <p>错误信息：${err.message} </p>
  //       ${errorList}
  //       `
  // });
};

export function commonError(title = "请求错误", err) {
  alert(title);
  return err;
  // Notification.error({
  //   title: title,
  //   position: "bottom-right",
  //   duration: 1500,
  //   dangerouslyUseHTMLString: true,
  //   message: `<p>状态码：${err.status} </p>
  //       <p>错误类型：${err.error} </p>
  //       <p>错误信息：${err.message} </p>`
  // });
}
